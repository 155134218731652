import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLinkStyled, NavCtaStyled } from './NavLink.style';

const NavLink = ({ cta, accentLink, menuItem, breakpoint, t, onClick, className, text, exact }) =>
    cta ? (
        <NavCtaStyled
            onClick={onClick}
            breakpoint={breakpoint}
            to={menuItem.url}
            className={className}
            exact={exact}
        >
            {text || t(menuItem.i18nKey)}
        </NavCtaStyled>
    ) : (
        <NavLinkStyled
            onClick={onClick}
            accentLink={accentLink}
            breakpoint={breakpoint}
            to={menuItem.url}
            tag={menuItem.tag}
            className={className}
            exact={exact}
        >
            {text || t(menuItem.i18nKey)}
        </NavLinkStyled>
    );

export default withTranslation()(NavLink);
