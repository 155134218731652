/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'components/Link';
import Anchor from 'components/Anchor';
import { colors } from 'theme';
import { NavLink } from 'react-router-dom';

const navItemBottomBorder = css`
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        background-color: ${({ theme }) => theme.palette.accent};
    }

    &:after {
        transition: transform 0.1s ease-out, background-color 0.1s ease-in-out;
        transform-origin: 0 100%;
        transform: scaleY(0);
    }
`;

const NavCtaStyled = styled(Link).attrs({
    variant: Link.Variant.Button.CTA,
})`
    display: none;
    margin-left: ${({ theme }) => theme.spacing[1.5]};

    @media screen and (min-width: ${({ breakpoint }) => breakpoint}px) {
        display: flex;
    }
`;

const activeClassName = 'active';
const NavLinkStyled = styled(({ accentLink, to, tag, breakpoint, ...rest }) =>
    tag === 'a' ? <Anchor href={to} {...rest} /> : <NavLink to={to} {...rest} />
).attrs({
    activeClassName,
})`
    display: none;
    align-items: center;

    height: 100%;

    text-decoration: none;
    padding: ${({ theme }) => `0 ${theme.spacing[1.5]}`};
    color: ${({ theme, accentLink }) => (accentLink ? theme.palette.accent : theme.palette.text)};

    position: relative;

    &:hover {
        color: ${({ accentLink }) =>
            accentLink
                ? colors.accent[500].modifiers.hover.value
                : colors.greys[500].modifiers.hover.value};
    }

    // Get rid of the annoying flash on iOS
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    // iOS persists the hover state when clicking links for some reason.
    // Get rid of the hover effect for touch devices by setting the original color again
    @media (hover: none) {
        &:hover {
            color: ${({ theme, accentLink }) =>
                accentLink ? theme.palette.accent : theme.palette.text};
        }
    }

    ${navItemBottomBorder};
    &.${activeClassName} {
        &:after {
            transform: scaleY(1);
        }
    }

    @media screen and (min-width: ${({ breakpoint }) => breakpoint}px) {
        display: flex;
    }
`;

export { NavLinkStyled, NavCtaStyled };
